import Slider from 'react-slick'
import { useCookies } from 'react-cookie'
import { API } from '../../config'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './slider.css'
import { useEffect, useState } from 'react';

let SliderCont = (props) => {
  const [cookie, setCookie, removeCookie] = useCookies({})
  const [browser, setBrowser] = useState('')
  const [mobil, setMobil] = useState('')

  useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setMobil('Dispositivo mobil')
    } else {
      setMobil('Computador escritorio o portatil')
    }

    if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
      setBrowser('Opera')
    }
    else if(navigator.userAgent.indexOf("Chrome") != -1 ) {
      setBrowser('Chrome')
    }
    else if(navigator.userAgent.indexOf("Safari") != -1) {
      setBrowser('Safari')
    }
    else if(navigator.userAgent.indexOf("Firefox") != -1 )  {
      setBrowser('Firefox')
    }
    else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) {//IF IE > 1
      setBrowser('IE')
    }
    else {
      setBrowser('unknown')
    }
  }, [])

  let metrics = (evt, title, desc, data) => {
    if (cookie.accesUser) {
      let userID = cookie.accesUser.data.user._id

      let body = {
        name: title,
        description: desc,
        data: data,
        user: userID,
      }

      let conf = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
        body: JSON.stringify(body),
      }

      fetch(`${API}/metrics/`, conf)
        .then(res => res.json())
        .then(res => {
          console.log(res);
        })
    }
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <div className="slideContainer">
      <Slider className="slideComponent" {...settings}>
        {
          props.productsList.map((ele, idx) => {
            return (
              <a className="productInfoSlide" key={idx} href={ele.url} target="_blank" onClick={e => {metrics(e, "btn-producto-click", `Click - ${ele.name}`, {browser: browser, device: mobil})}}>
                <div className={"imgProductSlide img-" + idx}></div>
                <p>{ele.name}</p>
                <p className="price">{ele.price}</p>
              </a>
            )
          })
        }
      </Slider>
    </div>
  )
}

export default SliderCont
