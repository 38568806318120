let Btn = (props) => {
  return (
    <a className={`btnMenu`} href={"home?section=#" + props.link}>
      <span className="sup"></span>
      <span className="rig"></span>
      <span className="infer"></span>
      <span className="left"></span>
      <div className={`textImg ${props.className}`}>{props.text}</div>
    </a>
  )
}

export default Btn
