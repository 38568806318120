import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Page404 from './pages/page404/page404'
import Entrance from './pages/entrance/entrance'
import Cover from './pages/cover/cover';
import Admin from './pages/admin/admin'
import Room from './pages/room/room'


let Routes = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Entrance}/>
        <Route exact path="/cover" component={Cover}/>
        <Route exact path="/admin" component={Admin}/>
        <Route exact path="/home" component={Room}/>
        <Route component={Page404} />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
