import { useState, useEffect } from 'react'
import InputEmail from '../../components/inputs/input-email/inputEmail'
import InputName from '../../components/inputs/input-text/inputText-name'
import CheckBox from '../../components/inputs/checkBox/checkBox'
import Loader from 'react-loader-spinner'
import { useCookies } from 'react-cookie'
import { API } from '../../config'
import { User, Mail } from 'react-feather';
import genderImg from '../../images/gender.png'
import alertMsg from '../../components/alert'


import './entrance.css'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

let Entrance = ({ history }) => {
  const [option, setOption] = useState(0)
  const [newUser, setNewUser] = useState({ nameComplete: '', email: '', gender: '' })
  const [checkStatus, setCheckStatus] = useState(false)
  const [loanding, setLoanding] = useState(false)
  const [switchEntrance, setSwitchEntrance] = useState('login') // register
  const [email, setEmail] = useState('')
  const [cookie, setCookie] = useCookies({})

  useEffect(() => {

    return () => {
      // UnMount
    }
  }, [])

  const onChangeHandlerNewUser = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value })
  }

  const submitRegiser = (e) => {
    e.preventDefault();
    setLoanding(true)

    if (newUser.nameComplete.trim() === '' || newUser.email.trim() === '') {
      alertMsg('Faltan campos por llenar', false)
      return
    }

    if(newUser.gender === '') {
      alertMsg('Selecciona un genero', false)
      return
    }

    let expires = new Date()
    let hours = process.env.REACT_APP_COOKIE_EXPIRE_TIME_HOURS
    expires.setTime(expires.getTime() + (hours * 60 * 60 * 1000))

    let body = { ...newUser }
    body.email = body.email.toLocaleLowerCase()

    let conf = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify(body),
    };

    fetch(`${API}/register/`, conf)
      .then(res => {
        if (res.status === 404) {
          console.log("Error, endpoint no encontrado");
        } else {
          return res.json()
        }
      })
      .then(res => {
        console.log(res);

        if (res) {
          if (res.error) {
            if (res.error === 'DuplicatedError') {
              alertMsg('¡El usuario ya está registrado!', false)
            }
          } else {
            if (newUser.email === process.env.REACT_APP_USER_ADMIN) {
              setCookie('adminAccess', JSON.stringify(res), { path: "/", expires })
              history.push('/admin')
            } else if (process.env.REACT_APP_USER_CLIEN_ADMIN_ACCESS === newUser.email) {
              setCookie('clientAdminAccess', JSON.stringify(res), { path: "/", expires })
              history.push('/admin-client')
            } else {
              setCookie('accesUser', JSON.stringify({data: {user: res}}), { path: "/", expires })
              history.push('/home?section=#player') //home?section=#player
            }
            alertMsg('¡Perfecto! Muchas gracias por registrarte', true)
          }
        }
        setNewUser({ nameComplete: '', email: '', gender: 'Hombre' })

        setLoanding(false)
      }).catch((err) => {
        console.log(err);
        setLoanding(false)
      })
  }

  const submitLogin = (e) => {
    e.preventDefault()
    setLoanding(true)

    if (email.trim() === '') {
      alertMsg('Ingresa un email', false)
      return
    }

    let expires = new Date()
    let hours = process.env.REACT_APP_COOKIE_EXPIRE_TIME_HOURS
    expires.setTime(expires.getTime() + (hours * 60 * 60 * 1000))

    let body = {
      user: email.toLocaleLowerCase(),
      password: "",
    }

    let conf = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify(body),
    };

    fetch(`${API}/auth/login`, conf)
      .then(res => res.json())
      .then(res => {

        if (email !== process.env.REACT_APP_USER_ADMIN && res.error) {
          alertMsg('¡El usuario no está registrado!', false)

        } else {
          if (email === process.env.REACT_APP_USER_ADMIN) {
            setCookie('adminAccess', JSON.stringify(res), { path: "/", expires })
            history.push('/admin')
          } else {
            setCookie('accesUser', JSON.stringify(res), { path: "/", expires })
            history.push('/home?section=#player') //home?section=#player
          }
        }
        setEmail('')
        setLoanding(false)
      })
  }

  const register = () => {
    return (
      <div className='entranceOption'>
        <div className='entranceMenu'>
          <div className='entranceMenuSelected'><h4>REGISTRATE</h4></div>
          <div className='entranceMenuUnselected' onClick={() => setOption(1)}><h4>YA ESTOY REGISTRADO</h4></div>
        </div>
        <div className='entranceFormContainer'>
          <form onSubmit={submitRegiser}>
            <div className='entranceMenuInput'>
              <div className='entranceMenuInputImg' >
                <User style={{ color: 'white' }} className='entranceMenuInputImg' />
              </div>
              <input value={newUser.nameComplete} name='nameComplete' type="text" placeholder='NOMBRE' onChange={onChangeHandlerNewUser} />
            </div>
            <div className='entranceMenuInput'>
              <div className='entranceMenuInputImg' >
                <Mail style={{ color: 'white' }} />
              </div>
              <input value={newUser.email} name='email' type="email" placeholder='E-MAIL' onChange={onChangeHandlerNewUser} />
            </div>
            <div className='entranceMenuInput'>
              <img src={genderImg} alt="" className='entranceMenuInputImg' />
              <select value={newUser.gender} name="gender" onChange={onChangeHandlerNewUser}>
                <option value="" style={{color: "#DDD"}}>Genero</option>
                <option value="Hombre">Hombre</option>
                <option value="Mujer">Mujer</option>
                <option value="No especifica">No especifica</option>
              </select>
            </div>
            <div className='entranceMenuInputTerms'>
              <input type="checkbox" id='terms' required /><span htmlFor="terms" className='entrancePageTerm'>He leído y acepto la <a href='https://www.reebok.co/help-topics-privacy_policy.html'>política de protección de datos personales</a></span>
            </div>
            <button className='entranceMenuInputSend' type='submit'>REGISTRAR</button>
          </form>
        </div>
      </div>
    )
  }

  const login = () => {
    return (
      <div className='entranceOption'>
        <div className='entranceMenu'>
          <div className='entranceMenuUnselected' onClick={() => setOption(0)}><h4>REGISTRATE</h4></div>
          <div className='entranceMenuSelected'><h4>YA ESTOY REGISTRADO</h4></div>
        </div>
        <div className='entranceFormContainer'>
          <form onSubmit={submitLogin}>
            <div className='entranceMenuInput'>
              <Mail style={{ color: 'white' }} className='entranceMenuInputImg' />
              <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder='E-MAIL' />
            </div>
            <p className='entrancePageEmailLabel'>Ingresa el e-mail con el que te registraste.</p>
            <button className='entranceMenuInputSend' type='submit'>INGRESAR</button>
          </form>
        </div>
      </div>
    )
  }


  return (
    <div className="entranceContainer">
      <img id="imgPIXEL" src="https://ads.sonataplatform.com/pixel/attribution/60429403d67225002fbe2617"/>
      <div className="entranceLeft"></div>

      <div className="entranceRight">
        {
          option === 0 ?
            register() : login()
        }
      </div>
    </div>
  )
}

export default Entrance
