import { useState, useEffect } from 'react'

let RandomName = (props) => {
  const [names, setNames] = useState('')
  let i = 0

  useEffect(() => {
    const intervalTime = setInterval(() => {
      setNames(props.usersOnline[i++ % props.usersOnline.length].user.nameComplete)
    },150);

    return () => {
      clearInterval(intervalTime)
    }
  }, [])

  return (
    <div className="nameSection">
      <p className="nameShow" id="nameShow">{names}</p>
    </div>
  )
}

export default RandomName
