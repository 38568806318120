import React, { useState, useEffect } from 'react'
import logo from '../../images/logo.png'
import Modal from '../../components/modalComplete/modalComp'

import './cover.css'


let Cover = (props) => {
  const [close, setClose] = useState(true)

  useEffect(() => {

    window.removeEventListener("beforeunload", (ev) => {
      ev.preventDefault();

      return ev.returnValue = 'Hola';
    });

    return () => {
      window.removeEventListener("beforeunload", (ev) => {
        ev.preventDefault();

        return ev.returnValue = 'Hola';
      });
    }

  }, [])

  return (
    <div className='coverPage'>
      {
        !close && <Modal state={{ close: [close, setClose] }}/>
      }

      <div className='coverPageHeader'>
        <img src={logo} alt="" />
      </div>
      <div className='coverPageContent'>
        <div className='coverPageLeft'>

        </div>
        <div className='coverPageRight'>
          <div className='coverPageMessage'>
            <h2 className='semiBold coverPageTitle'>¡GRACIAS POR REGISTRARTE!</h2>
            <div className='coverPageMsg'>
              <p className='semiBold'>¡Prepárate para romper tu propio record!</p>
              <p>Llega tan lejos como quieras, este14 de marzo desde las 8:00 AM y vive 5 horas de fitness sin interrupciones, <p>con <b className='semiBold'>Nano X1</b></p></p>
            </div>
            <button className="agendaBtn" onClick={e => {setClose(false)}}>Agenda</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cover
