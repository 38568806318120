import AgendaImg from '../../components/agenda/agenda'
import './agenda.css'

let Agenda = (props) => {
  return (
    <div className="agendaContainer" id="diary">
      <div className="imgBg"></div>
      <div className='container'>
        <div className="title">
          <div className="iconAgenda"></div>
          <h2>AGENDA <span>RECORD NANO X1</span></h2>
        </div>
        <AgendaImg />
      </div>
    </div>
  )
}

export default Agenda