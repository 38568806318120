import { useState, useEffect } from 'react'
import { API } from '../../config'

import './memory.css'

let Memory = (props) => {
  const [iframe, setIframe] = useState(null)

  useEffect(() => {

    fetch(`${API}/iframe/${props.iframe}`)
      .then(res => res.json())
      .then(res => {
        setIframe(res.iframe)
      })

    return () => {
      // Unmount
      setIframe(null)
    }
  }, [])

  return (
    <div className="videoContMem">
      {
        iframe
        ? <div className="iframeCont" dangerouslySetInnerHTML={{__html: iframe}}></div>
        : <h1>Cargando...</h1>
      }
      <span className="sup"></span>
      <span className="rig"></span>
      <span className="infer"></span>
      <span className="left"></span>
    </div>
  )
}

export default Memory
