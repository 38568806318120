import React, { useState, useEffect } from 'react'
import { API } from '../../config'

import { withRouter } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import MenuDesk from '../../components/menu/menuDesk/menuDesk'
import Home from '../../pages/home/home'
import Player from '../../pages/player/player'
import Store from '../../pages/store/store'
import Agenda from '../../pages/agenda/agenda'
import Trainers from '../../pages/trainers/trainers'
import Works from '../../pages/works/works'
import ModalComplete from '../../components/modalComplete/modalCompMemories'

import './room.css'


const Room = ({history}) => {
  const [workouts, setWorkouts] = useState({})
  const [cookie, setCookie, removeCookie] = useCookies({})
  const [online, setOnline] = useState([])
  const [browser, setBrowser] = useState('')
  const [mobil, setMobil] = useState('')

  useEffect(() => {


    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setMobil('Dispositivo mobil')
    } else {
      setMobil('Computador escritorio o portatil')
    }

    if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
      setBrowser('Opera')
    }
    else if(navigator.userAgent.indexOf("Chrome") != -1 ) {
      setBrowser('Chrome')
    }
    else if(navigator.userAgent.indexOf("Safari") != -1) {
      setBrowser('Safari')
    }
    else if(navigator.userAgent.indexOf("Firefox") != -1 )  {
      setBrowser('Firefox')
    }
    else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) {//IF IE > 1
      setBrowser('IE')
    }
    else {
      setBrowser('unknown')
    }




    let conf = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
    }

    fetch(`${API}/online/`, conf)
      .then(res => res.json())
      .then(res => {
        let listEmails = []

        res.map((ele) => {
          listEmails.push(ele.user.email)
        })
        setOnline(listEmails)

        if (listEmails.indexOf(user.email.toLocaleLowerCase()) === -1) {

          let body = {
            user: user.email.toLocaleLowerCase(),
            password: "",
          }

          let conf = {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-type': 'application/json',
            },
            body: JSON.stringify(body),
          };

          fetch(`${API}/auth/login`, conf)
            .then(res => res.json())
            .then(res => {
            })
        }
      })

    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();

        if (cookie.accesUser) {
          let userID = cookie.accesUser.data.user._id


          let conf = {
            method: 'PATCH',
            headers: {
              'Accept': 'application/json',
              'Content-type': 'application/json',
            },
          }

          fetch(`${API}/auth/logout/${userID}`, conf)
            .then(res => res.json())
            .then(res => {
            console.log(res);
          })
        }

      return ev.returnValue = 'Hola';
    });

    return () => {

      window.addEventListener("beforeunload", (ev) => {
        ev.preventDefault();

        if (cookie.accesUser) {
          let userID = cookie.accesUser.data.user._id


          let conf = {
            method: 'PATCH',
            headers: {
              'Accept': 'application/json',
              'Content-type': 'application/json',
            },
          }

          fetch(`${API}/auth/logout/${userID}`, conf)
          .then(res => res.json())
          .then(res => {
            console.log(res);
          })
        }

        return ev.returnValue = 'Adios';
      });
    }

  }, [])

  let user = null;

  let phone = '3164448844'
  let country = '57'
  let urlWhatsApp = `https://wa.me/${country}${phone}`

  if (cookie.accesUser) {
    user = cookie.accesUser.data.user
  } else if (cookie.adminAccess) {
    user = cookie.adminAccess.data
  }

  if (!user) {
    history.push('/')
    return (<div></div>)
  }

  let metrics = (evt, title, desc, data) => {
    if (cookie.accesUser) {
      let userID = cookie.accesUser.data.user._id

      let body = {
        name: title,
        description: desc,
        data: data,
        user: userID,
      }

      let conf = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
        body: JSON.stringify(body),
      }

      fetch(`${API}/metrics/`, conf)
        .then(res => res.json())
        .then(res => {
          console.log(res);
        })
    }
  }

  return (
    <div className='room'>
      {
        workouts.close === false &&
        <ModalComplete state={{ workouts: [workouts, setWorkouts] }} />
      }

      <MenuDesk history={history} />

      <div className="sections">
        <Home />
        <Player />
        <Store />
        <Agenda />
        <Trainers />
        <Works state={{ workouts: [workouts, setWorkouts] }}/>
      </div>

      <div className="whatsappCont">
        <a className="whatsApp" href={urlWhatsApp} target="_blank" rel="noreferrer" onClick={e => {metrics(e, "btn-whatsapp-click", "Click - Teinda por wts", {browser: browser, device: mobil})}}>
          <div className="imgWhatsapp"></div>
        </a>
        <div className="text">Compras por<br /> Whatsapp</div>
      </div>
    </div>
  )

}


export default withRouter(Room);
