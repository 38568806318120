import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie'
import { API } from '../../config'
import Slider from '../../components/slider/slider'
import './store.css'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

let Store = (props) => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [cookie, setCookie, removeCookie] = useCookies({})
  const [browser, setBrowser] = useState('')
  const [mobil, setMobil] = useState('')

  let productsList = [
    {name: "NANO X1 Night Black", price: "$399.900", url: "https://www.reebok.co/tenis-nano-x1/FZ0633.html"},
    {name: "NANO X1 True Grey 1", price: "$399.900", url: "https://www.reebok.co/tenis-nano-x1/FZ0636.html"},
    {name: "NANO X1 White / Black", price: "$399.900", url: "https://www.reebok.co/tenis-nano-x1/FZ0634.html"},
    {name: "LICRAS HIGH-RISE LUX PERFORM PERFORATED", price: "$199.900", url: "https://www.reebok.co/licras-high-rise-lux-perform-perforated/GL2621.html"},
    {name: "ESQUELETO ACTIVCHILL", price: "$99.900", url: "https://www.reebok.co/esqueleto-activchill/FU2918.html"},
    {name: "CROP TOP PERFORM PERFORATED", price: "$99.900", url: "https://www.reebok.co/crop-top-perform-perforated/GL2605.html"},
    {name: "BUZO CON CAPUCHA DREAMBLEND COTTON", price: "$169.900", url: "https://www.reebok.co/buzo-con-capucha-dreamblend-cotton/GI6748.html"},
    {name: "SHORTS EPIC", price: "$149.900", url: "https://www.reebok.co/shorts-epic/GJ6321.html"},
    {name: "BUZO CON CAPUCHA Y CIERRE PERFORMANCE", price: "$209.900", url: "https://www.reebok.co/buzo-con-capucha-y-cierre-performance/GJ6416.html"},
  ]

  useEffect(() => {

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setMobil('Dispositivo mobil')
    } else {
      setMobil('Computador escritorio o portatil')
    }

    if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
      setBrowser('Opera')
    }
    else if(navigator.userAgent.indexOf("Chrome") != -1 ) {
      setBrowser('Chrome')
    }
    else if(navigator.userAgent.indexOf("Safari") != -1) {
      setBrowser('Safari')
    }
    else if(navigator.userAgent.indexOf("Firefox") != -1 )  {
      setBrowser('Firefox')
    }
    else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) {//IF IE > 1
      setBrowser('IE')
    }
    else {
      setBrowser('unknown')
    }

    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  let metrics = (evt, title, desc, data) => {
    if (cookie.accesUser) {
      let userID = cookie.accesUser.data.user._id

      let body = {
        name: title,
        description: desc,
        data: data,
        user: userID,
      }

      let conf = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
        body: JSON.stringify(body),
      }

      fetch(`${API}/metrics/`, conf)
        .then(res => res.json())
        .then(res => {
          console.log(res);
        })
    }
  }


  if (windowDimensions.width > 768) {
    return (
      <div className="storeContainer" id="store">
        <div className="imgBg"></div>
        <a href="https://www.reebok.co/search?q=nano%20x1" target="_blank" className="buy" onClick={e => {metrics(e, "btn-tienda-click", "Click - tienda general", {browser: browser, device: mobil})}}>Comprar ahora &#10142;</a>
        <div className="productsContainer">
          {
            productsList.map((ele, idx) => {
              return (
                <a className="productInfo" key={idx} href={ele.url} target="_blank" onClick={e => {metrics(e, "btn-producto-click", `Click - ${ele.name}`, {browser: browser, device: mobil})}}>
                  <div className={"imgProduct img-" + idx}></div>
                  <p>{ele.name}</p>
                  <p className="price">{ele.price}</p>
                </a>
              )
            })
          }
        </div>
      </div>
    )
  } else {
    return (
      <div className="storeContainer" id="store">
        <div className="imgBg"></div>
        <a href="https://www.reebok.co/search?q=nano%20x1" target="_blank" className="buy" onClick={e => {metrics(e, "btn-tienda-click", "Click - tienda general", {browser: browser, device: mobil})}}>Comprar ahora &#10142;</a>
        <div className="productsContainer">
          {
            <Slider productsList={productsList} />
          }
        </div>
      </div>
    )
  }
}

export default Store