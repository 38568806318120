import { useState, useEffect } from 'react'
import Agenda from '../agenda/agenda'
import Memory from '../memoryPopUp/memory'

import './modalMemories.css'

let ModalComplite = (props) => {
  const { workouts: [workouts, setWorkouts] } = { workouts: useState({}), ...(props.state || {}) }

  const closeAnyway = (evt) => {
    if (evt.target.className === "bodyModal") {
      setWorkouts({
        ...workouts,
        close: true
      })
    }
  }

  const escFunction = (event) => {
    if(event.keyCode === 27) {
      setWorkouts({
        ...workouts,
        close: true
      })
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div className="bodyModaMemories" onClick={e => {closeAnyway(e)}}>
      <button className="closeModal" onClick={e => {setWorkouts({...workouts, close: true})}}>X</button>
      <div className="modalCont">
        <Memory iframe={workouts.memory} />
      </div>
    </div>
  )
}

export default ModalComplite
