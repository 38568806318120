import { useState } from 'react'
import Agenda from '../agenda/agenda'
import './modal.css'

let ModalComplite = (props) => {
  const { close: [close, setClose] } = { close: useState(''), ...(props.state || {}) }

  const closeAnyway = (evt) => {
    if (evt.target.className === "bodyModal") {
      setClose(true)
    }
  }

  return (
    <div className="bodyModal" onClick={e => {closeAnyway(e)}}>
      <button className="closeModal" onClick={e => {setClose(true)}}>X</button>
      <div className="modalCont">
        <Agenda />
      </div>
    </div>
  )
}

export default ModalComplite
