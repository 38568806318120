import React, { useState, useEffect } from 'react'
import { API } from '../../config'
import UploadFile from '../uploadFile/uploadFileImg'
import InputNumber from '../inputs/input-number/inputNumber'
import Counter from '../trivia/counter/counter'
import RandomName from '../randomName/randomName'

import "./sorteo.css"

let Sorteo = (props) => {

  const [file, setFile] = useState(undefined)
  const [number, setNumber] = useState('')
  const [startCounter, setStartCounter] = useState(false)
  const [preview, setPreview] = useState()
  const [nameWin, setNameWin] = useState({})

  useEffect(() => {

    if (!file) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(file)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => {
      URL.revokeObjectURL(objectUrl)
    }
  }, [file])

  let startTimer = (evt) => {
    if (preview) {
      setStartCounter(true)
    }

    fetch(`${API}/online/lottery/online`)
      .then(res => res.json())
      .then(res => {
        console.log(res);
        setNameWin(res)
      })
  }

  return (
    <div className="sorteoContainer">
      <div className="graphContainer">
        <img className="bgImg" src={preview} alt="imageBg"/>

        <div className={startCounter ? 'ruleta-rotate' : 'ruleta'}>
        </div>

        <div className="center">
          {
            startCounter &&
            <Counter initialSeconds={number} state={{ startCounter: [startCounter, setStartCounter] }}/>
          }
        </div>

        {
          startCounter
          ? <RandomName usersOnline={props.usersOnline}/>
          : <div className="nameSection">
              <p className="nameShow" id="nameWin">{nameWin.user ? nameWin.user.nameComplete : "Nombre"}</p>
            </div>
        }
      </div>
      <div className="controlsContainer">
        <UploadFile id="imgBgSorteo" textBtn="Subir" text="Selecciona una imagen" state={{ file: [file, setFile] }}/>
        <InputNumber placeHolder="Ingrese los segundos" state={{ number: [number, setNumber] }}/>
        {
          props.usersOnline.length > 0 &&
          <button className="btnActions" onClick={e => {startTimer(e.target)}}>Start</button>
        }
        {
          nameWin.user &&
          <div className="winerSection">
            <h1>{nameWin.user.nameComplete}</h1>
            <p>{nameWin.user.email}</p>
          </div>
        }
      </div>
    </div>
  )
}

export default Sorteo
