import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { API, ws } from '../../config'
import './trainer.css'

let Trainers = (props) => {
  const [browser, setBrowser] = useState('')
  const [mobil, setMobil] = useState('')
  const [cookie, setCookie, removeCookie] = useCookies({})

  useEffect(() => {

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setMobil('Dispositivo mobil')
    } else {
      setMobil('Computador escritorio o portatil')
    }

    if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
      setBrowser('Opera')
    }
    else if(navigator.userAgent.indexOf("Chrome") != -1 ) {
      setBrowser('Chrome')
    }
    else if(navigator.userAgent.indexOf("Safari") != -1) {
      setBrowser('Safari')
    }
    else if(navigator.userAgent.indexOf("Firefox") != -1 )  {
      setBrowser('Firefox')
    }
    else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) {//IF IE > 1
      setBrowser('IE')
    }
    else {
      setBrowser('unknown')
    }

  }, [])

  const trainers = [
    {name: "Les Mills", nickName: "Entrenamientos grupales inspiradores", link: "https://www.instagram.com/lesmillslatino/"},
    {name: "Diana Motlak", nickName: "Deportista y emprendedora fitness", link: "https://www.instagram.com/dianamotlak/"},
    {name: "Silvana Hurtado", nickName: "Personal Fitness", link: "https://www.instagram.com/silvy_hurtado/ "},
    {name: "Issa Vasquez", nickName: "Influencer Fitness", link: "https://www.instagram.com/issavasquez_/"},
    {name: "Cheli", nickName: "Fisioterapeuta y coach", link: "https://www.instagram.com/cheli.ft/"},
    {name: "Tatiana Ussa", nickName: "Atleta Ironman", link: "https://www.instagram.com/tatianaugirardi/"},
    {name: "Smartfit + Zona Prieta", nickName: "Comunidad fitness", link: "https://www.instagram.com/smartfitco/"},
    {name: "Manuela Uribe", nickName: "Yoga y bienestar", link: "https://www.instagram.com/manuelauribef/"},
    {name: "Ricki Alvarez", nickName: "Deportista ", link: "https://www.instagram.com/rickialvarezv/"},
    {name: "Natalia Castillo", nickName: "Health coach", link: "https://www.instagram.com/castillonatalia/"},
    {name: "Paula Suarez", nickName: "Nutrición y entrenamiento para mujeres", link: "https://www.instagram.com/pa.suarez/"},
    {name: "Caro Mejia", nickName: "Dance", link: "https://www.instagram.com/caromejiaaraujo/"},
    {name: "Mia Arango", nickName: "Deportista y wellness", link: "https://www.instagram.com/mia.wellnesslab/"},
  ]

  let metrics = (evt, title, desc, data) => {
    if (cookie.accesUser) {
      let userID = cookie.accesUser.data.user._id

      let body = {
        name: title,
        description: desc,
        data: data,
        user: userID,
      }

      let conf = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
        body: JSON.stringify(body),
      }

      fetch(`${API}/metrics/`, conf)
        .then(res => res.json())
        .then(res => {
          console.log(res);
        })
    }
  }


  return (
    <div className="trainersContainer" id="trainers">
      <div className="imgBg"></div>
      <div className="container">
        <div className="title">
          <div className="iconAgenda"></div>
          <h2>ENTRENADORES <span>RECORD NANO X1</span></h2>
        </div>

        <div className="trainerInfo">
          {
            trainers.map((ele, idx) => {
              return (
                <a className="trainerCont" key={idx} href={ele.link} target="_blank" onClick={e => {metrics(e, "btn-trainer-click", `Click - ${ele.name}`, {browser: browser, device: mobil})}}>
                  <div className={ele.name + " ImgTrainer"}></div>
                  <h2 className="trainerName">{ele.name}</h2>
                  <h3 className="nick">{ele.nickName}</h3>
                </a>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default Trainers
