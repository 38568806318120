import './home.css'

let Home = (props) => {
  return (
    <div className="homeContainer" id="home">
      <div className="imgBg"></div>
      <div className="imgTitle"></div>
      <div className="texts">
        <h2>¡Prepárate para romper tu propio record!</h2>
        <p>Llega tan lejos como quieras,<br /> 5 horas de fitness sin interrupciones, con Nano X1.</p>
      </div>
    </div>
  )
}

export default Home
