import React, { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { API, ws } from '../../config'
import './works.css'

let Works = (props) => {
  const { workouts: [workouts, setWorkouts] } = { workouts: useState({}), ...(props.state || {}) }
  const [browser, setBrowser] = useState('')
  const [mobil, setMobil] = useState('')
  const [cookie, setCookie, removeCookie] = useCookies({})

  useEffect(() => {

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setMobil('Dispositivo mobil')
    } else {
      setMobil('Computador escritorio o portatil')
    }

    if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
      setBrowser('Opera')
    }
    else if(navigator.userAgent.indexOf("Chrome") != -1 ) {
      setBrowser('Chrome')
    }
    else if(navigator.userAgent.indexOf("Safari") != -1) {
      setBrowser('Safari')
    }
    else if(navigator.userAgent.indexOf("Firefox") != -1 )  {
      setBrowser('Firefox')
    }
    else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) {//IF IE > 1
      setBrowser('IE')
    }
    else {
      setBrowser('unknown')
    }

  }, [])

  let videos = [
    {name: "SUMPLEMENTOS Y ESTIGMAS CARB", memories: "1", key: "suplementos"},
    {name: "PRE Y POST WORKOUT", memories: "2", key: "preypost"},
    {name: "BAILE", memories: "3", key: "baile"},
    {name: "RECETA PARA NIÑOS", memories: "4", key: "receta"},
    {name: "Power Fitness Challenge", memories: "5", key: "power"},
  ]

  let workoutsFunc = (evt, workName, key) => {
    setWorkouts({close: false, memory: key})
    metrics(evt, "btn-workouts-click", `Click - ${workName}`, {browser: browser, device: mobil})
  }

  let metrics = (evt, title, desc, data) => {
    if (cookie.accesUser) {
      let userID = cookie.accesUser.data.user._id

      let body = {
        name: title,
        description: desc,
        data: data,
        user: userID,
      }

      let conf = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
        body: JSON.stringify(body),
      }

      fetch(`${API}/metrics/`, conf)
        .then(res => res.json())
        .then(res => {
          console.log(res);
        })
    }
  }

  return (
    <div className="worksContainer" id="workouts">
      <div className="imgBg"></div>

      <div className="container">
        <div className="videoCont">
          <h2>COMPLEMENTA TU  ESTILO FITNESS CON ESTOS CONTENIDOS</h2>
          <div className="videos">
            {
              videos.map((ele, idx) => {
                return (
                  <div className="videoCont" key={idx} onClick={e => {workoutsFunc(e, ele.name, ele.key)}}>
                    <h3 className="title">{ele.name}</h3>
                    <div className={`minImg img-${idx + 1}`}></div>
                  </div>
                )
              })
            }
          </div>
          <span className="sup"></span>
          <span className="rig"></span>
          <span className="infer"></span>
          <span className="left"></span>
        </div>
      </div>
    </div>
  )
}

export default Works
