import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import io from "socket.io-client";
import { API, ws } from '../../config'
import IframeMan from '../../components/admin/iframeMan'
import SlideCheck from '../../components/inputs/slideCheck/slideCheck'

import './admin.css'
import MemoriesMan from '../../components/admin/memoriesMan';
import Sorteo from '../../components/sorteo/sorteo';

const socket = io.connect(ws, { transports: ['websocket'] });

let Admin = ({ history }) => {
  const [cookie, setCookie, removeCookie] = useCookies({})
  const [registers, setRegisters] = useState([])
  const [online, setOnline] = useState([])
  const [banner, setBanner] = useState(false)
  const [zoom, setZoom] = useState(false)

  useEffect(() => {

    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();

        if (cookie.adminAccess) {
          let userID = cookie.adminAccess.data.user._id


          let conf = {
            method: 'PATCH',
            headers: {
              'Accept': 'application/json',
              'Content-type': 'application/json',
            },
          }

          fetch(`${API}/auth/logout/${userID}`, conf)
            .then(res => res.json())
            .then(res => {
            console.log(res);
          })
        }

      return ev.returnValue = 'Hola';
    });

    return () => {

      window.addEventListener("beforeunload", (ev) => {
        ev.preventDefault();

        if (cookie.adminAccess) {
          let userID = cookie.adminAccess.data.user._id


          let conf = {
            method: 'PATCH',
            headers: {
              'Accept': 'application/json',
              'Content-type': 'application/json',
            },
          }

          fetch(`${API}/auth/logout/${userID}`, conf)
          .then(res => res.json())
          .then(res => {
            console.log(res);
          })
        }

        return ev.returnValue = 'Adios';
      });
    }

  }, [])


  if (!cookie.adminAccess) {
    history.push('/')
  }

  /* =================================================== */
  /* ===========USUARIOS REGISTRADOS =================== */

  let handleClickRegisters = (evt) => {
    let conf = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
    }

    fetch(`${API}/register/`, conf)
      .then(res => res.json())
      .then(res => {
        console.log(res);
        setRegisters(res)
      })
  }

  let handleClickOnline = (evt) => {
    let conf = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
    }

    fetch(`${API}/online/`, conf)
      .then(res => res.json())
      .then(res => {
        console.log(res);
        setOnline(res)
      })
  }

  /* ===========USUARIOS REGISTRADOS =================== */
  /* =================================================== */

  const logOut = () => {
    removeCookie('adminAccess')
    removeCookie('accesUser')
    removeCookie('clientAdminAccess')

    if (cookie.adminAccess) {
      let userID = cookie.adminAccess.data.user._id


      let conf = {
        method: 'PATCH',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
      }

      fetch(`${API}/auth/logout/${userID}`, conf)
      .then(res => res.json())
      .then(res => {
        console.log(res);
        history.push('/')
      })
    }
  }

  if (zoom) {
    socket.emit('zoom', zoom)
  } else {
    socket.emit('zoom', zoom)
  }

  if (banner) {
    socket.emit('banner', banner)
  } else {
    socket.emit('banner', banner)
  }

  return (
    <div className="adminContainer">
      <div className="backCont">
        <button className='backBtn close' onClick={logOut}>
          <div className="arrow">
            &#10008;
          </div>
          <p>Cerrar Sesión</p>
        </button>
      </div>

      <div className="card chatAdmin">
        <div className="title">
          <h2>Foro</h2>
        </div>
        <div className="BannerControl">
          <h2>Banner on/off</h2>
          <SlideCheck state={{ slideCheck: [banner, setBanner] }} />
        </div>

        <div className="BannerControl">
          <h2>Zoom on/off</h2>
          <SlideCheck state={{ slideCheck: [zoom, setZoom] }} />
        </div>
      </div>

      <div className="card onlineCont">
        <div className="title">
          <h2>Usuarios online</h2>
        </div>
        <div className="listCont">
          <div className="action">
            {
              online.length === 1
                ? <h3>{`${online.length} Usuario online`}</h3>
                : <h3>{`${online.length} Usuarios online`}</h3>
            }
            <button onClick={e => { handleClickOnline(e) }} className='adminButton'>Cargar lista</button>
          </div>
          <div className="listRegisters">
            {
              online.map((ele) => {
                return (
                  <div className="userInfo" key={ele._id}>
                    <p>{ele.user.nameComplete}</p>
                    <p>{ele.user.email}</p>
                    <p>{ele.user.dni}</p>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>

      <div className="card gamesCont">
        <div className="title">
          <h2>Actividades</h2>
        </div>
        <Sorteo usersOnline={online} />
      </div>

      <div className="card registers">
        <div className="title">
          <h2>Usuarios registrados</h2>
        </div>
        <div className="listCont">
          <div className="action">
            {
              registers.length === 1
                ? <h3>{`${registers.length} Usuario registrado`}</h3>
                : <h3>{`${registers.length} Usuarios registrados`}</h3>
            }
            <button onClick={e => { handleClickRegisters(e) }} className='adminButton'>Cargar lista</button>
          </div>
          <div className="listRegisters">
            {
              registers.map((ele) => {
                return (
                  <div className="userInfo" key={ele._id}>
                    <p>{ele.name}</p>
                    <p>{ele.email}</p>
                    <p>{ele.mobile}</p>
                    <p>{ele.document}</p>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>

      <div className="card memories">
        <div className="title">
          <h2>Memorias</h2>
        </div>
        <MemoriesMan socket={socket}/>
      </div>

      <div className="card streaming">
        <IframeMan socket={socket}/>
      </div>

    </div>
  )
}

export default Admin
