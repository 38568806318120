import { useEffect, useState } from 'react'

import './agenda.css'
import AgendaPopUpDesk from '../../images/POPUP-AGENDA.jpg'
import AgendaPopUpMobile from '../../images/POPUP-AGENDA-MOBILE.jpg'


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

let Agenda = (props) => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {

    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className="agendaCont">
      <div className="imgAgenda" style={{ backgroundImage: `url(${windowDimensions.width <= 768 ? AgendaPopUpMobile : AgendaPopUpDesk})` }}></div>
    </div>
  )
}

export default Agenda
