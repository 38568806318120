import React, { useState, useEffect } from "react";
import { ws } from '../../config'
import io from "socket.io-client";
const socket = io.connect(ws, { transports: ['websocket'] });


function Stream({ iframe }) {
  const [iframClient, setIframeClien] = useState(null)

  useEffect(() => {
    socket.on("streaming", data => {
      setIframeClien(data.msg);
    });
    return () => { socket.off() }
  }, []);

  if (!iframClient) {
    return (
      <div className="iframeCont" dangerouslySetInnerHTML={{__html: iframe}}></div>
    )
  }

  return (
    <div className="iframeCont" dangerouslySetInnerHTML={{__html: iframClient}}></div>
  );
}

export default Stream;
