import React, { useState, useEffect } from 'react'
import { API } from '../../../config'
import { useCookies } from 'react-cookie'
import { Redirect } from 'react-router-dom'
import Btn from './btn'

import './menu.css'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

let MenuDesk = ({ history }, ...rest) => {

  const [cookie, setCookie, removeCookie] = useCookies({})
  const [isloged, setIsloged] = useState(true)
  const [openMenu, setOpenMenu] = useState(false)
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {

    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  let infoBtn = [
    {text: "En vivo", class: "live", link: "player"},
    {text: "Tienda", class: "store", link: "store"},
    {text: "Agenda", class: "agenda", link: 'diary'},
    {text: "Entrenadores", class: "trainers", link: "trainers"},
    {text: "Workouts + nutrición", class: "workouts", link: "workouts"},
  ]

  const logOut = (evt) => {
    if (cookie.accesUser) {
      let userID = cookie.accesUser.data.user._id


      let conf = {
        method: 'PATCH',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
      }

      fetch(`${API}/auth/logout/${userID}`, conf)
      .then(res => res.json())
      .then(res => {
        removeCookie('adminAccess')
        removeCookie('accesUser')
        console.log(res);
        setIsloged(false)
      })
    }

    history.push('/')
  }

  let handleClickMenu = (evt) => {
    let menuCont = document.getElementById('#menuCont')
    evt.target.className === "bntMenuHamb-close" ? evt.target.className = "bntMenuHamb-open" : evt.target.className = "bntMenuHamb-close"

    evt.target.className === "bntMenuHamb-close" ? setOpenMenu(false) : setOpenMenu(true)
  }

  if (windowDimensions.width > 768) {
    return (
      <div className="menuContainer">
        <div className="logo"></div>
        <div className="bntMenuHamb-close" onClick={e => {handleClickMenu(e)}}></div>
        <div className="btnMenuCont" id="menuCont">
          {
            infoBtn.map((ele, idx) => {
              return (
                <Btn text={ele.text} className={ele.class} key={idx} link={ele.link}/>
              )
            })
          }
        </div>
        <div className='backBtn close' onClick={e => logOut(e)}></div>
      </div>
    )
  } else {
    return (
      <div className="menuContainer">
        <div className="logo"></div>
        <div className="bntMenuHamb-close" onClick={e => {handleClickMenu(e)}}></div>
        {
          openMenu === true &&
          <div className="btnMenuCont" id="menuCont">
            {
              infoBtn.map((ele, idx) => {
                return (
                  <Btn text={ele.text} className={ele.class} key={idx} link={ele.link}/>
                )
              })
            }
          </div>
        }
        <div className='backBtn close' onClick={e => logOut(e)}></div>
      </div>
    )
  }
}

export default MenuDesk
