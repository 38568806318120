import { useState } from 'react'

import './upload.css'

let UploadFileImg = (props) => {
  const [imgToSave, setImgToSave] = useState(null)
  const { file: [file, setFile] } = { file: useState({}), ...(props.state || {}) }

  /* =================================================== */
  /* ======= AGREGAR IMAGENES A LA AGENDA ============== */

  const handleUploadFileImage = (evt) => {
    setImgToSave(evt.target.files[0])
  }

  const handleSubmit = (evt) => {
    evt.preventDefault()
    setFile(imgToSave)
  }


  /* ======= AGREGAR IMAGENES A LA AGENDA ============== */
  /* =================================================== */
  return (
    <form onSubmit={e => {handleSubmit(e)}}>
      <div className="containerForm">
        <input type="file" id={props.id} accept="image/png, image/jpeg" onChange={e => {handleUploadFileImage(e)}}/>
        <label htmlFor={props.id} className="inputFileLb">{props.text}</label>
        {
          imgToSave &&
          <span>{imgToSave.name}</span>
        }
      </div>
      <button className="submit" type="submit">{props.textBtn}</button>
    </form>
  )
}

export default UploadFileImg
