import { useState } from "react"

import './number.css'

let InputNumber = (props) => {
  const { number: [number, setNumber] } = { number: useState(''), ...(props.state || {}) }

  return (
    <input
      required
      type="number"
      className={props.className}
      id={props.id}
      value={number}
      placeholder={props.placeHolder || "Ingrese un número"}
      onChange={e => {setNumber(e.target.value)}}
    />
  )
}

export default InputNumber
