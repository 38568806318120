import React, { useState, useEffect } from 'react'
import Stream from '../../components/room/stream';
import { useCookies } from 'react-cookie'
import { API, ws } from '../../config'
import io from "socket.io-client";

import './player.css'

const socket = io.connect(ws, { transports: ['websocket'] });

let Player = (props) => {
  const [iframClient, setIframeClient] = useState('')
  const [bannerCont, setBannerCont] = useState(false)
  const [zoomBtn, setZoomBtn] = useState(false)
  const [browser, setBrowser] = useState('')
  const [mobil, setMobil] = useState('')
  const [cookie, setCookie, removeCookie] = useCookies({})

  useEffect(() => {

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setMobil('Dispositivo mobil')
    } else {
      setMobil('Computador escritorio o portatil')
    }

    if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
      setBrowser('Opera')
    }
    else if(navigator.userAgent.indexOf("Chrome") != -1 ) {
      setBrowser('Chrome')
    }
    else if(navigator.userAgent.indexOf("Safari") != -1) {
      setBrowser('Safari')
    }
    else if(navigator.userAgent.indexOf("Firefox") != -1 )  {
      setBrowser('Firefox')
    }
    else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) {//IF IE > 1
      setBrowser('IE')
    }
    else {
      setBrowser('unknown')
    }



    let conf = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
    };

    fetch(`${API}/iframe/iframeDB`, conf)
      .then(res => res.json())
      .then(res => {
        setIframeClient(res.iframe)
        console.log('Iframe loaded from Fetch');
      })


    socket.on("banner", data => {
      setBannerCont(data)
    });
    socket.on("zoom", data => {
      setZoomBtn(data)
    });

    return () => { socket.off() }

  }, [])

  let metrics = (evt, title, desc, data) => {
    if (cookie.accesUser) {
      let userID = cookie.accesUser.data.user._id

      let body = {
        name: title,
        description: desc,
        data: data,
        user: userID,
      }

      let conf = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
        body: JSON.stringify(body),
      }

      fetch(`${API}/metrics/`, conf)
        .then(res => res.json())
        .then(res => {
          console.log(res);
        })
    }
  }

  return (
    <div className="playerContainer" id="player">
      <div className="imgBg"></div>
      {
        zoomBtn &&
        <a className="zoomBtn" href="https://us02web.zoom.us/j/7452676217" target="_blank">
          <p>Únete a Zoom</p>
        </a>
      }
      <div className="playerbannerCont">
        <div className="streamingBorders">
          <Stream socket={socket} iframe={iframClient} />
          <span className="sup"></span>
          <span className="rig"></span>
          <span className="infer"></span>
          <span className="left"></span>
        </div>
        {
          bannerCont &&
          <a href="https://www.smartfit.com.co/" target="_blank" className="bannerCont" onClick={e => {metrics(e, "btn-bannerSmartFit-click", "Click - Pagina general de SmartFit", {browser: browser, device: mobil})}}></a>
        }
      </div>
    </div>
  )
}

export default Player